.bbInsightsContent {
  margin-top: 30px;
}

.bbSettingsContent {
  margin-top: 50px;
}

.bbCareContent {
  margin-top: 30px;
}

.bbInsightsFeatures {
  margin-top: 16px;
}

.bbControlPanelContent {
  margin-top: 35px;

  div[class*="bbWorkflowsTopBar--"],
  div[class*="bbWorkflowsTableContainer--"] {
    margin-left: 0;
  }
  div[class*="bbWorkflowItems--"] {
    left: 0px;
    width: unset;
  }

  div[class*="bbWorkflowsMenuWithTopOffset--"] {
    display: none;
  }

  div[class*="bbManagedPanel--"] {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .bbInsightsContent {
    margin-top: -20px;
  }

  .bbCareContent {
    margin-top: 0;
  }

  .bbControlPanelContent {
    margin-top: 0;
    padding-bottom: 32px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;