.bbAIWorkflowStep2 {
  min-height: 400px;

  div[class*="bbAccordionHeader--"] {
    height: auto;
    align-items: flex-start;
  }

  span[class*="bbExpansionPanelBadge--"] {
    color: var(--color-on-secondary);
    margin-right: 12px;
    letter-spacing: 0.12px;
  }

  span[class*="bbExpansionPanelBadgeDanger--"] {
    color: var(--color-danger-1);
    background-color: var(--color-danger-100);
  }

  div[class*="bbExpansionPanelTitle--"] {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .bbFiltersPanelCustomHeader {
    display: flex;
    align-items: center;

    &Optional {
      margin-left: 4px;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-lg);
      color: var(--color-on-surface-subtle-1);
    }

    &Subtitle {
      font-weight: var(--font-weight-medium);
    }
  }

  .bbFiltersPanelCustomHeaderSubtitle {
    margin-top: 12px;
    font-size: var(--font-size-md);
    color: var(--color-on-surface-subtle-1);
  }
}

:global {
  .ant-form-vertical {
    :local {
      .bbAIWorkflowStep2Footer {
        width: 100%;
        margin: 0;

        :global {
          .ant-form-item-control-input-content {
            display: flex;
            justify-content: flex-end;
            margin: 0;
            margin-top: 36px;
            justify-content: space-between;
          }
        }
      }

      .bbAIWorkflowStep2OkBtn {
        gap: 8px;

        svg {
          width: 20px;
          height: 20px;
          fill: var(--color-on-primary);
        }
      }

      .bbAIWorkflowStep2BackBtn {
        gap: 8px;

        svg {
          width: 20px;
          height: 20px;
          fill: var(--color-primary-1);
        }
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;