.bbWorkflowCreationStepWide {
  padding: 20px 32px;
}

.bbWorkflowCreationStepWide,
.bbWorkflowCreationStep {
  padding: 24px;

  .bbWorkflowTypeError {
    display: flex;
    margin-top: 12px;
    margin-bottom: 32px;
    align-items: center;
    color: var(--color-danger-1);
    font-size: var(--font-size-lg);

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  .bbWorkflowModalTitle {
    font-size: var(--headline-h4-size);
    font-weight: bold;
    margin-bottom: 24px;
  }

  .bbWorkflowSectionRadio {
    display: block;
  }

  .bbWorkflowCreationStepFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 36px;
  }

  .bbWorkflowLimits {
    display: flex;
    gap: 48px;
    margin-top: 24px;
  }

  &BackBtn,
  &Btn {
    svg {
      width: 20px;
      height: 20px;
      fill: var(--color-on-primary);
    }
  }

  &BackBtn svg {
    fill: var(--color-primary-1);
  }

  .bbWorkflowModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 52px;

    .bbWorkflowModalTitle {
      margin-bottom: 0;
    }

    &Toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 8px;
      &Label {
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-xl);
      }
    }
  }

  .bbWorkflowCreationSectionTitle {
    display: flex;
    margin-bottom: 16px;
    margin-top: 16px;
    color: var(--color-on-surface);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);

    svg {
      position: relative;
      top: 3px;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      fill: var(--color-on-surface-subtle-2);
    }
  }

  .bbWorkflowTypeCardWrapper {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 12px), 1fr));

    div[class*="bbWorkflowTypeCard"] {
      padding: 12px 12px 12px 4px;
    }
  }

  .bbWorkflowTaggingFlowRadio {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  .bbTaggingFlowCard {
    padding: 20px;
    flex: 1;
    color: var(--color-on-surface);
    max-width: 414px;

    span[class*="bbRadioBtnContentWrapper--"] {
      display: flex;
      flex-direction: column;
      margin-left: 0;
    }

    input[class*="bbRadioBtnInput--"] {
      position: absolute;
      top: 0;
      right: 0;
    }

    &Icon {
      width: 24px;
      height: 24px;
      fill: var(--color-on-surface-subtle-2);
    }

    &TitleWrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 12px;
    }

    &Title {
      font-size: var(--headline-h4-size);
      font-weight: var(--font-weight-extrabold);
      letter-spacing: 0.2px;
    }

    &Subtitle {
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
      letter-spacing: 0.14px;
      margin-bottom: 12px;
    }

    ul {
      padding-left: 20px;
      margin: 0;
    }

    &Description {
      padding: 8px 12px;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-md);
      border-radius: var(--border-radius-md);
      background-color: var(--color-surface-1);
    }

    &Illustration {
      margin-top: 42px;
      margin-bottom: 22px;
      display: flex;
      align-items: center;
    }
  }

  .bbTaggingFlowCardSelected {
    border: 2px solid var(--color-primary-1);
  }
}

.bbFlexGrow {
  flex-grow: 1;
}

.bbWorkflowPreview {
  height: 520px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &Inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &Title {
    font-size: var(--headline-h4-size);
    font-weight: var(--font-weight-bold);
  }

  &Description {
    font-size: var(--font-size-xl);
    color: var(--color-on-surface-subtle-1);
  }

  &Info {
    font-size: var(--font-size-lg);
    color: var(--color-on-surface-subtle-1);
    margin-bottom: -8px;
  }

  &List {
    display: flex;
    flex-grow: 1;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    min-height: 0;
    padding: 24px 0;
    margin-bottom: 24px;

    &More {
      color: var(--color-on-surface-subtle-2);
    }
  }
}

@media (max-width: 600px) {
  .bbWorkflowCreationStep {
    .bbWorkflowTypeCardWrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;

      div[class*="bbWorkflowTypeCard"] {
        padding: 12px 12px 12px 4px;
        width: 100%;
        margin-bottom: 0;

        div[class*="bbWorkflowTypeTitle"] {
          font-size: var(--font-size-lg);
        }
      }
    }

    .bbWorkflowModalTitle {
      font-size: var(--font-size-xl);
    }

    .bbWorkflowCreationSectionTitle {
      font-size: var(--font-size-sm);

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .bbWorkflowCreationStepFooter {
      justify-content: center;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;