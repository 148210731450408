.bbAIWorkflowStep3 {
  :global(.ant-form-item) {
    margin: 0;
  }

  &CategoryAccordion {
    margin: 0;
    margin-bottom: 32px;
    padding: 16px 20px;
    box-shadow: var(--shadow-sm-1);

    div[class*="bbAccordionHeader--"] {
      height: 20px;
    }

    div[class*="bbAccordionHeaderLeft--"] {
      span {
        color: var(--color-on-surface);
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-extrabold);
        letter-spacing: 0.16px;
      }
    }

    .bbCategoryWrapperError,
    .bbExampleError {
      border: 2px solid var(--color-danger-1);

      &:hover {
        border: 2px solid var(--color-danger-1);
      }
    }

    .bbCategory {
      height: 107px;
      width: 100%;
      padding: 8px 16px;
      line-height: 150%;
      margin-top: 8px;
      font-size: var(--font-size-lg);

      textarea {
        font-size: var(--font-size-lg);
        color: var(--color-on-surface);
      }
    }

    .bbCategoryWrapper {
      margin-top: 8px;
    }

    .bbCategoryLabel,
    .bbExamplesTitle {
      color: var(--color-on-surface);
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-extrabold);
      letter-spacing: 0.14px;
    }

    .bbExamplesTitle {
      margin-top: 28px;

      span {
        color: var(--color-on-surface-subtle-1);
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-medium);
        letter-spacing: 0.14px;
      }
    }

    .bbExample {
      width: 100%;
      margin-top: 4px;
      font-size: var(--font-size-lg);
      display: flex;
      align-items: center;
      height: 40px;

      input {
        font-size: var(--font-size-lg);
        color: var(--color-on-surface);
      }
    }

    .bbAddExampleBtn {
      width: 100%;
      margin-bottom: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      font-size: var(--font-size-md);
      border: 1px solid var(--color-surface-5);
      box-shadow: none;
      margin-top: 8px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-1);
      }
    }

    .bbExampleContent {
      display: flex;
      align-items: flex-end;
      gap: 8px;
    }

    .bbExampleLabel {
      color: var(--color-on-surface);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-medium);
      letter-spacing: 0.13px;
    }

    .bbExampleFormItem {
      flex: 1;
    }

    .bbExampleWrapper {
      margin-top: 8px;
    }

    .bbDeleteExampleBtn {
      width: 40px;
      height: 40px;
      padding: 10px;

      svg {
        width: 20px;
        height: 20px;
        fill: var(--color-danger-1);
      }
    }

    div[class*="bbDivider--"] {
      border-top: 1px solid var(--color-surface-5);
      margin-bottom: 28px;
      margin-top: 28px;
    }

    .bbAIModalDropdown {
      svg {
        margin: 8px 0 8px 12px;
      }

      & > div {
        height: 32px;
        display: flex;
      }
    }

    div[class*="bbDeleteConfirmation--"] {
      margin-bottom: 12px;
    }
  }

  .bbAddCategoryBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: var(--font-size-md);
    border: 1px solid var(--color-surface-5);
    box-shadow: none;
    box-shadow: var(--shadow-sm-1);

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
    }
  }
}

.bbAIModalDropdownMenu {
  width: 160px;
  right: -10px;
  top: 10px;
  padding: 8px;
  box-shadow: var(--shadow-md-2);
  border-radius: var(--border-radius-lg);
}

.bbAIModalDropdownText {
  color: var(--color-primary-1);
  cursor: pointer;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 8px;

  &:hover {
    background-color: var(--color-primary-100);
  }

  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-primary-1);
  }
}

.bbAIWorkflowStep3Footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  margin-top: 36px;
  justify-content: space-between;
}

.bbAIWorkflowStep3OkBtn {
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-on-primary);
  }
}

.bbAIWorkflowStep3BackBtn {
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-primary-1);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;