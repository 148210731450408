.bbWorkflows {
  &Content {
    &Mobile {
      width: 100%;
    }

    .bbWorkflowsHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      h2 {
        margin: 0;
        padding: 0;
        font-size: var(--headline-h3-size);
        font-weight: var(--font-weight-bold);
        color: var(--color-on-surface);
      }
    }

    .bbWorkflowsTopBar {
      display: flex;
      justify-content: flex-end;
      margin-top: 32px;
      margin-left: 236px;
      position: relative;
      gap: 20px;

      .bbWorkflowsSearchInput {
        svg {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          fill: var(--color-on-surface-subtle-2);
        }
      }

      .bbWorkflowsCreateBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        white-space: nowrap;
        flex-shrink: 0;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--color-on-primary);
        }
      }

      &Mobile {
        margin: 0 0 24px 0;
        padding: 0;
        right: 0;

        .bbWorkflowsSearchInput,
        .bbWorkflowsSearchInputWrapper {
          display: flex;
          flex-grow: 1;
          width: auto;
        }

        .bbWorkflowsCreateBtn {
          min-width: auto;
        }
      }
    }

    .bbWorkflowsSearchInputWrapper {
      width: 220px;
      box-shadow: var(--shadow-sm-2);
    }
  }

  .bbWorkflowsTable {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0;
    padding: 0;

    &Container {
      padding: 0;
      margin-left: 265px;
      margin-top: 32px;

      &Mobile {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  .bbWorkflowItems {
    position: relative;
    display: grid;
    left: 225px;
    gap: 40px;
    padding: 32px 40px;
    width: calc(100% - 186px);

    &Mobile {
      display: flex;
      width: 100%;
      left: 0;
      flex-direction: column;
      padding: 0;
    }
  }

  .bbWorkflowItemsSm {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .bbWorkflowItemsMd {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 40px), 1fr));
  }

  .bbWorkflowItemsLg {
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 40px), 1fr));
  }
}

.bbDeleteModalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 36px;

  button:first-child {
    margin-right: 12px;
  }
}

.bbDeleteModalDescription {
  width: 370px;
  margin-top: 16px;
  font-size: var(--font-size-2xl);
}

.bbCreationModal {
  div[class*="bbModalBody--"] {
    border-radius: var(--border-radius-lg);
  }

  &Content {
    margin: 20px;
  }
}

@media (max-width: 600px) {
  .bbWorkflows {
    &Content {
      .bbWorkflowsTopBar {
        right: 0;
        margin-left: 0;
        justify-content: flex-start;
      }

      .bbWorkflowsSearchInputWrapper {
        width: 100%;
      }
    }
  }
}

div[class*="bbHeaderRow--"] {
  .bbTableCol {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    color: var(--color-text-primary);
    border-right: 1px solid var(--color-surface-5);
    flex: none;
    max-width: unset;

    &:first-child {
      width: 70px;
    }

    &:nth-child(2) {
      width: 222px;
    }

    &:nth-child(3) {
      width: calc(100% - 292px);
    }

    &:last-child {
      border-right: none;
    }

    &Icon {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      margin: 0 6px 0 4px;

      svg {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        fill: var(--color-on-surface-subtle-2);
      }
    }
  }
}

.bbTableRow {
  display: flex;

  font-size: var(--font-size-lg);
  border-bottom: 1px solid var(--color-surface-5);
  background-color: var(--color-grey-50);

  &:last-child {
    border-bottom: none;
  }

  &Even {
    background-color: transparent;
  }

  &Cell {
    display: flex;
    align-items: center;
    padding: 18px;
    border-right: 1px solid var(--color-surface-5);
    flex: none !important;
    max-width: unset !important;

    &:first-child {
      width: 70px;
    }

    &:nth-child(2) {
      width: 222px;
    }

    &:nth-child(3) {
      width: calc(100% - 292px);
    }

    &:last-child {
      border-right: none;
    }
  }

  &Title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h4 {
      margin: 0 0 4px 0;
      padding: 0;
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
      color: var(--color-text-primary);
    }

    p {
      margin: 0;
      padding: 0;
      font-size: var(--font-size-sm);
      color: var(--color-on-surface-subtle-1);
    }
  }

  .bbTableRowActions {
    border: 1px solid var(--color-surface-5);
  }
}

.bbTagIcon {
  height: 12px;
  width: 12px;
}

.bbWorkflowItemChipIcon {
  margin-bottom: 4px;
}

div.bbWorkflowItemChipIcon-azure {
  span[class*="bbChip--"] {
    background-color: var(--color-secondary-1);
    color: var(--color-primary-700);
  }

  svg {
    fill: var(--color-primary-1);
  }
}

div.bbWorkflowItemChipIcon-mint {
  span[class*="bbChip--"] {
    background-color: #d2ebee;
    color: #1d9aaa;
  }

  svg {
    fill: #1d9aaa;
  }
}

div.bbWorkflowItemChipIcon-lilac {
  span[class*="bbChip--"] {
    background-color: #eee4ff;
    color: #7723ff;
  }

  svg {
    fill: #7723ff;
  }
}

div.bbWorkflowItemChipIcon-slate {
  span[class*="bbChip--"] {
    background-color: #cdd7d8;
    color: #03363d;
  }

  svg {
    fill: #03363d;
  }
}

div.bbWorkflowItemChipIcon-magenta {
  span[class*="bbChip--"] {
    background-color: #ffeffb;
    color: #b2449e;
  }

  svg {
    fill: #b2449e;
  }
}

div.bbWorkflowItemChipIcon-ai_gradient {
  margin-left: 5px;

  span[class*="bbChip--"] {
    background: linear-gradient(270deg, #0048e5 0%, #625af5 74.65%, #8a62fc 116.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span[class*="bbChip--"]::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: var(--border-radius-md);
    background: linear-gradient(
      270deg,
      rgba(0, 72, 229, 0.1) 0%,
      rgba(98, 90, 245, 0.1) 43.74%,
      rgba(252, 98, 229, 0.1) 116.64%
    );
  }
}

.bbDiscardModalContent {
  font-size: var(--font-size-2xl);

  div[class*="bbModalHeaderTitle--"] {
    padding-left: 16px;
  }

  .bbDiscardModalBody {
    padding: 0px 32px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;