@category-tag-color: #7723ff;
@category-tag-color-light: #eee4ff;
@category-post_tag-color: #b2449e;
@category-post_tag-color-light: #ffeffb;
@category-zendesk-color: #03363d;
@category-zendesk-color-light: #e6ebec;
@category-archive-color: #1d9aaa;
@category-archive-color-light: #d2ebef;
@category-email_alert-background: rgba(227, 107, 0, 0.1);

.bbWorkflowCreationModal {
  .bbWorkflowTypeCard {
    border: 2px solid var(--color-on-primary);

    .bbWorkflowTypeCardRadioLabel {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }

    .bbWorkflowType {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &Icon {
        width: 32px;
        height: 32px;
        display: flex;
        margin-right: 16px;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius-xxl);

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &Content {
        display: flex;
        align-items: center;

        &Locked {
          opacity: 0.5;
        }
      }

      &Icon-sentiment {
        background: var(--color-secondary-success-1);
      }

      &Icon-tag {
        background: @category-tag-color-light;

        svg {
          fill: @category-tag-color;
        }
      }

      &Icon-post_tag {
        background: @category-post_tag-color-light;

        svg {
          fill: @category-post_tag-color;
        }
      }

      &Icon-archive {
        background: @category-archive-color-light;

        svg {
          fill: @category-archive-color;
        }
      }

      &Icon-zendesk {
        background: @category-zendesk-color-light;

        svg {
          fill: @category-zendesk-color;
        }
      }

      &Icon-reply {
        background: var(--color-secondary-1);

        svg {
          fill: var(--color-primary-1);
        }
      }

      &Icon-hide {
        background: var(--color-secondary-danger-1);

        svg {
          fill: var(--color-danger-1);
        }
      }

      &Icon-email_alert {
        background: @category-email_alert-background;

        svg {
          fill: var(--color-warning-600);
        }
      }

      &Icon-assign {
        background: var(--color-surface-4);

        svg {
          fill: var(--color-on-surface-subtle-1);
        }
      }

      &Title {
        font-size: var(--font-size-xl);
        color: var(--color-on-surface);
        font-weight: var(--font-weight-bold);
      }

      &LockChip {
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: var(--color-surface-1);
        padding: 4px 8px;
        border-radius: var(--border-radius-md);
        color: var(--color-on-surface-subtle-1);
        margin-right: 8px;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--color-on-surface-subtle-1);
        }
      }

      .bbWorkflowTypeTextWrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .bbWorkflowTypeCardSelected {
    border: 2px solid var(--color-primary-1);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;