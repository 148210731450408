@alert-background: #e36b001a;
@alert-color: #e36b00;
@archive-background: #e2f7fd;
@archive-color: #007b89;

.bbWorkflowCreationStepper {
  box-shadow: var(--shadow-md-1);
  background: white;
  height: 76px;
  display: flex;
  align-items: center;
  position: relative;

  > div:first-child {
    position: relative;
    left: -42px;
  }
}

.bbLoaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  flex-direction: column;

  .bbLoader {
    svg {
      width: 40px;
      height: 40px;
      fill: var(--color-primary-1);
    }
  }

  .bbLoaderText {
    width: 300px;
    color: var(--color-on-surface);
    text-align: center;
    font-size: var(--headline-h4-size);
    font-weight: var(--font-weight-extrabold);
    line-height: 120%;
    letter-spacing: 0.2px;
    margin-top: 32px;
  }
}

.bbAIWorkflowCreationLoader {
  margin: 0 auto;
  height: 400px;
}

.bbAIWorkflowCreationContent {
  display: flex;
  justify-content: center;

  .bbAIWorkflowCreationLeftPanel {
    min-width: 360px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: radial-gradient(
      134.17% 144.13% at -61.11% -41.16%,
      rgba(83, 68, 254, 0.68) 0%,
      #fff 100%
    );

    &Step {
      display: flex;
      padding: 2px 12px;
      align-items: center;
      gap: 4px;
      background: var(--color-surface-2);
      border-radius: 16px;
      text-transform: uppercase;
      margin-top: 10px;
      box-shadow: var(--shadow-md-1);
    }

    &StepText {
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-extrabold);
      background: linear-gradient(270deg, #0048e5 0%, #625af5 74.65%, #8a62fc 116.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &Title {
      color: var(--color-on-secondary);
      font-size: var(--headline-h4-size);
      font-weight: var(--font-weight-extrabold);
      line-height: 120%;
      letter-spacing: 0.2px;
      text-align: center;
      width: 270px;
    }

    &SubTitle {
      color: var(--color-on-secondary);
      font-size: var(--font-size-lg);
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.14px;
      text-align: center;
    }
  }

  .bbAIWorkflowCreationRightPanel {
    width: 100%;
    padding: 32px 32px 16px 32px;
    background: var(--color-surface-1);

    &Card {
      padding: 20px;
      background: var(--color-surface-2);
      border-radius: var(--border-radius-lg);
      box-shadow: var(--shadow-sm-1);
    }

    span[class*="bbFormErrorMessage"] {
      display: flex;
      gap: 4px;
      margin-top: 4px;
      align-items: center;
      color: var(--color-danger-1);
      font-size: var(--font-size-md);
      text-align: left;

      div[class*="bbHelp--"] {
        cursor: default;
      }

      svg {
        width: 16px;
        min-width: 16px;
        height: 16px;
        fill: var(--color-danger-1);
      }
    }
  }
}

div.bbDivider {
  border-top: 1px solid var(--color-surface-5);
}

span.bbOptional {
  color: var(--color-on-surface-subtle-1);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0.13px;
  margin-left: 2px;
}

.bbAINLPSuccess {
  &Title {
    color: var(--color-on-surface);
    text-align: center;
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    line-height: 150%;
    letter-spacing: 0.16px;
    margin-top: 64px;
    margin-bottom: 4px;
  }

  &Subtitle {
    color: var(--color-on-surface);
    text-align: center;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    line-height: 150%;
    letter-spacing: 0.13px;
    margin-bottom: 32px;
  }

  &CardTitle {
    color: var(--color-on-surface);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-extrabold);
    line-height: 150%;
    letter-spacing: 0.13px;
    margin-bottom: 8px;
    display: flex;
    gap: 12px;
  }

  &CardTitle-hide,
  &CardTitle-archive {
    width: 180px;
  }

  &CardTitle-sentiment,
  &CardTitle-email_alert {
    width: 215px;
  }

  &CardTypeIcon {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 40px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &CardTypeIcon-email_alert {
    background: @alert-background;

    svg {
      fill: @alert-color;
    }
  }

  &CardTypeIcon-sentiment {
    background: var(--color-secondary-success-1);
  }

  &CardTypeIcon-archive {
    background: @archive-background;

    svg {
      fill: @archive-color;
    }
  }

  &CardTypeIcon-hide {
    background: var(--color-secondary-danger-1);

    svg {
      fill: var(--color-danger-1);
    }
  }

  &Confetti {
    display: flex;
    justify-content: center;

    canvas {
      margin: 0 auto;
    }
  }

  &Cards {
    display: flex;
    width: 545px;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 32px;
    margin-bottom: 64px;
  }

  &CardBtn {
    gap: 6px;
    width: 248px;
    height: 32px;
    display: flex;
    justify-content: center;
    font-size: var(--font-size-md);

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
    }
  }
}

.bbChangeStepModalContent {
  font-size: var(--font-size-2xl);

  div[class*="bbModalHeaderTitle--"] {
    padding-left: 16px;
  }

  .bbChangeStepModalBody {
    padding: 0px 32px;
  }
}
@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;