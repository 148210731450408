.bbAIWorkflowStep4 {
  :global(.ant-form-item) {
    margin: 0;
  }

  .bbTagTaskActionWrapper {
    width: 84.5%;
  }

  &TaskAccordion {
    margin: 0;
    margin-bottom: 32px;
    padding: 16px 0 0 0;
    box-shadow: var(--shadow-sm-1);

    .bbTaskTitle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      svg {
        width: 20px;
        height: 20px;
        margin: 0;
        fill: var(--color-on-surface-subtle-2);
      }
    }

    div[class*="bbAccordionHeader--"] {
      height: 20px;
      padding: 0 20px;
      margin-bottom: 12px;
    }

    div[class*="bbAccordionBodyOverflow--"] {
      overflow: visible;
    }

    div[class*="bbAccordionBodyContent--"] {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 0 20px 16px 20px;
    }

    div[class*="bbAccordionHeaderLeft--"] {
      span {
        color: var(--color-on-surface);
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-extrabold);
        letter-spacing: 0.16px;
      }
    }

    .bbTaskDescription {
      color: var(--color-on-surface);
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-extrabold);
      line-height: 150%;
      letter-spacing: 0.16px;
    }

    .bbTaskTextarea {
      width: 100%;
      border-color: var(--color-surface-5);
      height: 117px;
      padding: 10px 16px;
    }

    .bbTaskTextareaError {
      border: 2px solid var(--color-danger-1);

      &:hover {
        border: 2px solid var(--color-danger-1);
      }
    }

    .bbTaskTextarea > div {
      height: 100%;
      overflow-y: unset;
    }

    .bbTaskTextareaInner {
      padding: 0;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
      line-height: 150%;
      letter-spacing: 0.14px;
    }

    .bbTaskEditButtons {
      display: flex;
      align-items: center;
      gap: 8px;

      .bbTaskCancelButton {
        min-width: 74px;
        display: flex;
        justify-content: center;
        border: 1px solid var(--color-surface-5);
        box-shadow: none;
      }

      .bbTaskSaveButton {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    .bbTaskTagWrapper {
      padding: 8px;
      border-radius: var(--border-radius-xl);
      background: var(--color-surface-1);
      display: flex;
      align-items: baseline;
      gap: 12px;

      .bbTaskTagText {
        color: var(--color-on-surface);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-medium);
      }

      div[class*="bbTagActionTypeSelect--"] {
        width: 130px;
        height: 32px;
        font-size: var(--font-size-md);
        padding: 6px 12px;
      }

      div[class*="bbBBTagsDropdown--"],
      div[class*="bbBBTagActionTypeSelect--"] {
        width: 100%;
      }

      div[class*="bbTagsDropdown--"] {
        display: block;
      }

      div[class*="bbTagAction--"] {
        gap: 12px;
        flex-wrap: nowrap;

        > span {
          color: var(--color-on-surface);
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-medium);
        }
      }

      button[class*="bbWorkflowItemFormTagSelect--"] {
        height: 32px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }

      div[class*="bbWorkflowItemFormTagSelectWrapper--"] {
        width: 100%;
      }

      span[class*="bbWorkflowItemFormTagSelectPlaceholder--"],
      div[class*="bbTagActionTypeSelectPlaceholder--"] > input {
        font-size: var(--font-size-md);
      }

      .bbTaskTagIcon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background: var(--color-surface-2);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 2px;

        svg {
          width: 14px;
          height: 14px;
          fill: #8d4bdf;
        }
      }

      span[class*="bbFormErrorMessage--"] {
        position: relative;
        left: 168px;
        font-size: var(--font-size-sm);

        svg {
          width: 14px;
          min-width: 14px;
          height: 14px;
        }
      }
    }

    .bbTaskError {
      border: 2px solid var(--color-danger-1);

      &:hover {
        border: 2px solid var(--color-danger-1);
      }
    }

    .bbTask {
      height: 107px;
      width: 100%;
      padding: 8px 16px;
      line-height: 150%;
      margin-top: 8px;
      font-size: var(--font-size-lg);

      textarea {
        font-size: var(--font-size-lg);
        color: var(--color-on-surface);
      }
    }

    .bbTaskLabel {
      color: var(--color-on-surface);
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-extrabold);
      letter-spacing: 0.14px;
    }

    .bbAIModalDropdown {
      svg {
        margin: 8px 0 8px 12px;
      }

      & > div {
        height: 32px;
        display: flex;
      }
    }

    div[class*="bbDeleteConfirmation--"] {
      margin-top: 0;
      margin-bottom: 4px;
    }
  }

  .bbAddTaskBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: var(--font-size-md);
    border: 1px solid var(--color-surface-5);
    box-shadow: none;
    box-shadow: var(--shadow-sm-1);

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
    }
  }
}

.bbAIModalDropdownMenu {
  width: 160px;
  right: -10px;
  top: 10px;
  padding: 8px;
  box-shadow: var(--shadow-md-2);
  border-radius: var(--border-radius-lg);
}

.bbAIModalDropdownText {
  color: var(--color-on-surface);
  cursor: pointer;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 8px;

  &:hover {
    color: var(--color-primary-1);
    background-color: var(--color-primary-100);

    svg {
      fill: var(--color-primary-1);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-on-surface-subtle-2);
  }
}

.bbAIWorkflowStep4Footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  margin-top: 36px;
  justify-content: space-between;
}

.bbAIWorkflowStep4OkBtn {
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-on-primary);
  }
}

.bbAIWorkflowStep4BackBtn {
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-primary-1);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;