.bbAIModal {
  &Header {
    padding: 12px 16px;
    gap: 20px;
    width: 100%;
    align-items: center;
    display: flex;
    background: linear-gradient(270deg, #0048e5 0%, #625af5 74.65%, #8a62fc 116.64%);

    &Icon {
      width: 32px;
      height: 32px;
      border: 2px solid var(--color-white);
      border-radius: var(--border-radius-xxl);
    }

    &StartButton {
      background: rgba(255, 255, 255, 0.2) !important;
      margin-top: 2px;
      height: 32px;
      color: var(--color-white);
      width: fit-content;
      display: flex;
      gap: 6px;
      padding: 6px 14px 6px 10px !important;

      span {
        font-size: var(--font-size-md);
      }

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-white);
      }
    }

    &Texts {
      display: flex;
      gap: 4px;
      flex-direction: column;

      > div {
        display: flex;
        gap: 8px;
        > span {
          font-size: var(--font-size-xl);
          margin-bottom: 0px;
          color: var(--color-white);
          font-weight: var(--font-weight-extrabold);
          line-height: 120%;
        }
      }

      p {
        font-size: var(--font-size-sm);
        margin-bottom: 0px;
        color: var(--color-white);
        font-weight: var(--font-weight-medium);
        line-height: 150%;
        white-space: pre-line;
      }
    }
  }

  &Close {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: var(--border-radius-xxl) !important;
    padding: 7px !important;
    background: rgba(255, 255, 255, 0.2) !important;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-white);
    }
  }

  &Interaction {
    display: flex;
    height: calc(100% - 175px);
    flex-direction: column;
    position: relative;
    overflow: hidden auto;
    &WithPadding {
      height: calc(100% - 215px);
    }
  }

  &PreviousThread {
    margin: 20px 0px 12px;
    display: flex;
    padding: 6px 14px 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-surface-5);
    color: var(--color-primary-700);
    font-size: var(--font-size-md);
    cursor: pointer;

    &Text {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    &Title {
      font-weight: var(--font-weight-extrabold);
    }

    svg {
      min-width: 16px;
      height: 16px;
      fill: var(--color-primary-700);
    }
  }

  &InputWrapper {
    padding: 12px;
    position: absolute;
    bottom: 0px;
    display: flex;
    gap: 10px;
    margin-top: auto;
    background-color: var(--color-white);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    width: 100%;

    textarea {
      font-size: var(--font-size-md);
      min-height: 42px;
    }
  }

  &Input {
    padding: 10px !important;
    border: none !important;
    box-shadow: none !important;
    max-height: 320px;
  }

  &SendButton {
    padding: 10px !important;
    background-color: var(--color-primary-700) !important;
    border-radius: var(--border-radius-xxl) !important;
    width: 40px;
    height: 40px;
    align-self: flex-end;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--color-white);
    }
  }

  &MessagesList {
    display: flex;
    flex-direction: column;
    padding: 20px 12px;
    gap: 12px;

    &Avatar {
      width: 48px;
      height: 48px;
      border-radius: var(--border-radius-xxl);
    }
  }

  &Message {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    &Content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      max-width: 100%;

      > span {
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-extrabold);
      }
    }

    &Text {
      padding: 4px 8px;
      align-self: stretch;
      border-radius: 4px 20px 20px;
      background-color: var(--color-primary-150);
      color: var(--color-grey-900);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-medium);

      * {
        font-size: var(--font-size-md);
        word-break: break-word;
      }

      pre {
        white-space: pre-line;
      }

      h1,
      h2,
      h3 {
        font-weight: var(--font-weight-extrabold);
      }

      p {
        margin-bottom: 0 !important;
      }

      svg {
        width: 14px;
        height: 14px;
        fill: #858ea6;
        position: relative;
        left: 4px;
        top: 2px;
        cursor: help;
      }
    }
  }

  &RightMessage {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    flex-direction: row-reverse;

    .bbAIModalMessageUser {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: var(--border-radius-xxl);
      background-color: var(--color-white);
      box-shadow: var(--shadow-sm-2);
    }

    .bbAIModalMessageContent {
      align-items: flex-end;
    }

    .bbAIModalMessageText {
      border-radius: 20px 4px 20px 20px;
      color: var(--color-white);
      background: linear-gradient(90deg, #004cef 0%, #4d87e4 100%);
    }
  }

  &InitialMessagesList {
    padding-left: 25px;

    &Tooltip {
      min-width: 290px;
    }
  }

  &TextDivier {
    margin: 10px 0;
  }

  &Actions {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &Active {
      width: fit-content;

      svg,
      path {
        fill: var(--color-primary-700) !important;
      }
    }

    button {
      flex: 1;
      justify-content: center;
      display: flex;
      gap: 8px;
      font-size: var(--font-size-md);
      padding: 6px;

      svg {
        width: 16px;
        height: 16px;
        fill: currentColor;
      }
    }

    &Button {
      width: 100%;

      svg,
      path {
        fill: currentColor;
      }

      &Disabled {
        cursor: not-allowed;
      }
    }

    &Hidden {
      display: none !important;
    }
  }

  &Loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 60px;
    align-items: center;

    svg {
      animation: spin 1s linear infinite;
      fill: var(--color-primary-700);
      width: 40px;
      height: 40px;
    }

    p {
      color: var(--color-grey-900);
      text-align: center;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-medium);
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bbCustomPromptsFooter {
  margin: 8px;
  padding-top: 8px;
  border-top: 1px solid var(--color-surface-5);
}

button.bbCustomPromptsFooterButton {
  flex: 1;
  justify-content: flex-start;
  display: flex;
  gap: 8px;
  font-size: var(--font-size-md);
  padding: 6px;
  width: 100%;
  color: var(--color-on-surface);

  &:hover {
    color: var(--color-primary-1);
  }

  svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;