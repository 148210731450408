.bbTagAction {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;

  > span {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-extrabold);
  }

  .bbTagActionTypeSelectPlaceholder {
    > input {
      color: var(--color-on-surface-subtle-1);
      font-weight: var(--font-weight-medium);
    }
  }
}

button.bbWorkflowItemFormTagSelect {
  min-width: 220px;
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background: var(--color-surface-2);
  border: none;
  box-shadow: var(--shadow-sm-2);
  width: 220px;
  overflow: hidden;

  .bbWorkflowItemFormTagSelectLabel {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
    font-size: var(--font-size-lg);
  }

  .bbWorkflowItemFormTagSelectPlaceholder {
    color: var(--color-on-surface-subtle-1);
    font-weight: var(--font-weight-medium);
  }

  .bbWorkflowItemFormTagSelectChevron {
    display: block;
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-900);
    }
  }
}

div.bbTagActionTypeSelect {
  background-color: var(--color-white);
  width: 220px;
  height: 40px;
  border: none;
  box-shadow: var(--shadow-sm-2);
}

.bbBBTagActionTypeSelect {
  height: 40px;
}

.bbBBTagsDropdown {
  cursor: pointer;
}

.bbTagsDropdownHeader {
  display: flex;
  flex-direction: column;
  gap: 12px;

  > span {
    font-size: var(--headline-h5-size);
    font-weight: var(--font-weight-bold);
  }
}

.bbWorkflowItemFormTagSelectWrapper {
  position: relative;
  top: -1px;
  height: unset;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;