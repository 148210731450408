.bbAIWorkflowStep5 {
  :global(.ant-form-item) {
    margin: 0;
  }

  &Title {
    color: var(--color-on-surface);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-extrabold);
    margin-bottom: 20px;
  }

  .bbTagTaskActionWrapper {
    width: 84.5%;
  }

  .bbTaskCard {
    margin: 0;
    margin-bottom: 32px;
    padding: 16px 20px;
    box-shadow: var(--shadow-sm-1);

    .bbTaskTitle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      color: var(--color-on-surface);
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-extrabold);
      margin-bottom: 20px;

      svg {
        width: 20px;
        height: 20px;
        margin: 0;
        fill: var(--color-on-surface-subtle-2);
      }
    }

    div[class*="bbAccordionHeader--"] {
      height: 20px;
    }

    div[class*="bbAccordionBodyOverflow--"] {
      overflow: visible;
    }

    div[class*="bbAccordionHeaderLeft--"] {
      span {
        color: var(--color-on-surface);
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-extrabold);
        letter-spacing: 0.16px;
      }
    }

    .bbExampleInput,
    .bbExampleInputWrapper {
      width: 100%;
      font-size: var(--font-size-md);
    }

    .bbExampleInputWrapper {
      border-color: var(--color-surface-5);
    }

    .bbExampleInputWrapperError {
      border: 2px solid var(--color-danger-1);

      &:hover {
        border: 2px solid var(--color-danger-1);
      }
    }

    .bbExampleEditButtons {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 12px;
      gap: 8px;

      .bbExampleCancelButton {
        min-width: 74px;
        display: flex;
        justify-content: center;
      }

      .bbExampleSaveButton {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    div[class*="bbDeleteConfirmation--"] {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .bbTaskDescription {
      color: #656565;
      font-size: var(--font-size-md);
      font-weight: 700;
    }

    .bbTaskExampleWrapper {
      .bbTaskExample {
        padding: 12px 12px 12px 20px;
        color: var(--color-on-secondary);
        background-color: var(--color-secondary-2);
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-bold);
        line-height: 150%;
        letter-spacing: 0.16px;
        margin-top: 20px;
        margin-bottom: 12px;
        border-radius: 2px 12px 12px 12px;

        .bbTaskExampleText {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .bbExampleActionBtns {
            display: flex;

            .bbEditExampleBtn,
            .bbDeleteExampleBtn {
              padding: 8px;
              border: none;
              background: transparent;
              margin-left: 8px;

              &:hover {
                border: none;
                background: transparent;
              }

              svg {
                width: 16px;
                height: 16px;
                fill: var(--color-primary-1);
              }
            }
          }
        }
      }

      .bbTagWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .bbTagWrapperForm {
          margin: 0;
          padding: 0;
        }

        .bbExampleShouldTagRadio {
          display: flex;
          align-items: center;
        }

        .bbExampleScoreChip {
          padding: 3px 8px;
          margin-left: 4px;
          color: var(--color-on-primary-dark);
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-bold);
          background-color: var(--color-primary-3);
          line-height: 150%;
          letter-spacing: 0.13px;
        }

        button {
          width: 180px;
          font-size: var(--font-size-md);
        }
      }
    }
  }

  .bbAddExampleBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: var(--font-size-md);
    border: 1px solid var(--color-surface-5);
    box-shadow: none;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
    }
  }
}

.bbAIWorkflowStep5Footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  margin-top: 4px;
  justify-content: space-between;
}

.bbAIWorkflowStep5OkBtn {
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-on-primary);
  }
}

.bbAIWorkflowStep5BackBtn {
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-primary-1);
  }
}

.bbScoreSelect {
  min-height: 311px;
  padding: 0 6px;

  span[class*="bbSimpleSelectOptionLabelDescription--"] {
    font-size: var(--font-size-md);
  }
}

.bbScoreSelectHeader {
  margin-top: 16px;
  margin-bottom: 12px;
  margin-left: 12px;
  color: var(--color-on-surface);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-extrabold);
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;