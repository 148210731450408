.bbAIWorkflowStep1 {
  &Hidden {
    display: none;
  }

  div[class*="bbDivider--"] {
    margin-top: 20px;
  }

  :global(.ant-form-item) {
    margin: 0;
  }

  div[class*="bbAdvancedUserPermissionsToggle"] {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-primary-900);
    font-weight: var(--font-weight-extrabold);
    margin-top: 20px;
  }

  div[class*="bbAdvancedUserPermissionsSection"] {
    display: flex;
    gap: 32px;
    align-items: center;

    > div {
      flex: 1;
    }
  }

  .bbAIWorkflowStep1Active {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-primary-900);
    font-weight: var(--font-weight-extrabold);
    margin-top: -4px;
    margin-bottom: 20px;
    justify-content: flex-end;
  }

  .bbFormItemDescription {
    margin-top: 20px !important;
  }

  .bbAIWorkflowStep1ModelUsage {
    display: flex;
    gap: 5px;
    flex-direction: column;
    margin-top: 20px;

    &Title {
      margin-bottom: 8px;
      color: var(--color-primary-900);
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-extrabold);
    }

    .bbCheckboxWrapper {
      display: flex;
      align-items: center;
    }

    .bbCheckbox {
      width: 20px;
      height: 20px;
    }

    .bbCheckboxLabel {
      margin-left: 8px;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
      position: relative;
      top: -4px;
    }
  }
}

.bbAIWorkflowStep1Footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;

  :global(.ant-form-item) {
    margin: 0;
  }
}

.bbAIWorkflowStep1OkBtn {
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-on-primary);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;