@category-tag-color: #7723ff;
@category-tag-color-light: #eee4ff;
@category-post_tag-color: #b2449e;
@category-post_tag-color-light: #ffeffb;
@category-zendesk-color: #03363d;
@category-zendesk-color-light: #e6ebec;
@category-archive-color: #1d9aaa;
@category-archive-color-light: #d2ebef;
@category-email_alert-background: rgba(227, 107, 0, 0.1);
@category-reply-color: var(--color-primary-700);
@category-reply-color-light: var(--color-secondary-1);

.bbWorkflowItemContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 20px !important;

  .bbWorkflowItemHeader {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .bbWorkflowItem {
    display: flex;
    min-height: 170px;
    flex-direction: column;
    justify-content: space-between;

    &CategoryIcon {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      margin-bottom: 8px;
      background-color: var(--color-surface-4);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-on-surface-subtle-1);
      }
    }

    &Category {
      color: var(--color-on-surface-subtle-1);
    }

    &Title {
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-bold);
      color: var(--color-on-surface);
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &Description {
      font-size: var(--font-size-sm);
      color: var(--color-on-surface-subtle-1);
      line-height: 150%;
      margin-bottom: 14px;

      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    &LabelWrapper {
      display: flex;
      justify-content: flex-end;
    }

    &Toggle {
      position: absolute;
      right: 0;
    }
  }

  .bbWorkflowItemCategoryAdditionalIcon {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-bottom: 8px;
    background-color: var(--color-surface-4);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .bbWorkflowItemCategoryIcon-tag,
  .bbWorkflowItemCategoryAdditionalIcon-tag,
  .bbWorkflowItemCategoryIcon-nlp_tag,
  .bbWorkflowItemCategoryAdditionalIcon-nlp_tag {
    color: @category-tag-color;
    background-color: @category-tag-color-light;

    svg {
      fill: @category-tag-color;
    }
  }

  .bbWorkflowItemCategoryIcon-reply,
  .bbWorkflowItemCategoryAdditionalIcon-reply {
    color: @category-reply-color;
    background-color: @category-reply-color-light;

    svg {
      fill: @category-reply-color;
    }
  }

  .bbWorkflowItemCategoryIcon-post_tag,
  .bbWorkflowItemCategoryAdditionalIcon-post_tag {
    color: @category-post_tag-color;
    background-color: @category-post_tag-color-light;

    svg {
      fill: @category-post_tag-color;
    }
  }

  .bbWorkflowItemCategoryIcon-archive,
  .bbWorkflowItemCategoryAdditionalIcon-archive {
    color: @category-archive-color;
    background-color: @category-archive-color-light;

    svg {
      fill: @category-archive-color;
    }
  }

  .bbWorkflowItemCategoryIcon-zendesk,
  .bbWorkflowItemCategoryAdditionalIcon-zendesk {
    color: @category-zendesk-color;
    background-color: @category-zendesk-color-light;

    svg {
      fill: @category-zendesk-color;
    }
  }

  .bbWorkflowItemCategoryIcon-email_alert,
  .bbWorkflowItemCategoryAdditionalIcon-email_alert {
    color: var(--color-warning-600);
    background-color: @category-email_alert-background;

    svg {
      fill: var(--color-warning-600);
    }
  }
  .bbWorkflowItemCategoryIcon-sentiment,
  .bbWorkflowItemCategoryAdditionalIcon-sentiment {
    background: var(--color-secondary-success-1);

    svg {
      fill: var(--color-success-1);
    }
  }

  .bbWorkflowItemCategoryIcon-hide,
  .bbWorkflowItemCategoryAdditionalIcon-hide {
    background: var(--color-secondary-danger-1);

    svg {
      fill: var(--color-danger-1);
    }
  }

  .bbWorkflowItemCategory,
  .bbAdditionalActionCategoryWrapper {
    display: inline;
    letter-spacing: 0.22px;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-bold);
  }

  .bbWorkflowItemCategory-zendesk {
    color: @category-zendesk-color;
  }

  .bbWorkflowItemCategory-archive {
    color: @category-archive-color;
  }

  .bbWorkflowItemCategory-tag,
  .bbWorkflowItemCategory-nlp_tag {
    color: @category-tag-color;
  }

  .bbWorkflowItemCategory-email_alert {
    color: var(--color-warning-600);
  }

  .bbWorkflowItemCategory-sentiment {
    color: var(--color-success-1);
  }

  .bbWorkflowItemCategory-hide {
    color: var(--color-danger-1);
  }

  &Disabled {
    .bbWorkflowItem {
      .bbWorkflowItemCategoryIcon,
      .bbWorkflowItemCategoryAdditionalIcon {
        background: var(--color-grey-100);

        svg {
          fill: var(--color-on-surface-subtle-1);
        }
      }

      .bbWorkflowItemCategory {
        color: var(--color-on-surface-subtle-1);
      }
    }
  }

  div.bbWorkflowItemChipIcon-ai_gradient {
    margin-left: 8px;

    span[class*="bbChip--"] {
      background: linear-gradient(270deg, #0048e5 0%, #625af5 74.65%, #8a62fc 116.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    span[class*="bbChip--"]::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: var(--border-radius-md);
      background: linear-gradient(
        270deg,
        rgba(0, 72, 229, 0.1) 0%,
        rgba(98, 90, 245, 0.1) 43.74%,
        rgba(252, 98, 229, 0.1) 116.64%
      );
    }
  }

  &.bbCanViewOrEdit:hover {
    cursor: pointer;
    box-shadow: var(--shadow-lg-2);

    .bbWorkflowItemTitle {
      color: var(--color-primary-1);
    }
  }

  .bbActionsDropdown {
    display: flex;
    justify-content: flex-end;
  }
}

.bbActionsDropdownMenu {
  & > * {
    padding: 5px 0;
    border-radius: 6px;
    background: white;
  }
}

.bbActionsDropdownTrigger {
  border: none;
  padding: 8px !important;

  & > * {
    height: 16px;
  }
}

li.bbActionItem {
  width: 200px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md);
  padding: 10px;
  margin-bottom: 0px;
  height: 32px;

  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-on-surface-subtle-2);
  }

  &:hover {
    color: var(--color-primary-1);

    svg {
      fill: var(--color-primary-1);
    }
  }
}

:global {
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0px;
    height: 32px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;