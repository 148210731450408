.bbAIWorkflowStep6 {
  :global(.ant-form-item) {
    margin: 0;
  }

  .bbAIWorkflowStep6Content {
    min-height: 400px;

    .bbMessages {
      margin-bottom: 32px;
      display: flex;
      gap: 8px;
      flex-direction: column;

      .bbMessageWrapper {
        display: flex;
        gap: 8px;
        align-items: flex-start;

        .bbUserProfile {
          display: flex;
          width: 24px;
          height: 24px;
          padding: 4.8px;
          justify-content: center;
          align-items: center;
          border-radius: var(--border-radius-xl);
          background: var(--color-surface-2);
          box-shadow: var(--shadow-sm-2);

          svg {
            width: 14.4px;
            height: 14.4px;
            fill: var(--color-on-surface);
          }
        }

        .bbMessage {
          width: 100%;
          padding: 12px 20px;
          border-radius: 2px 12px 12px 12px;
          background: var(--color-secondary-2);
          box-shadow: var(--shadow-sm-1);
          color: var(--color-on-secondary);
          font-size: var(--font-size-lg);

          &White {
            background: var(--color-surface-2);
          }

          &Text {
            font-weight: var(--font-weight-bold);
            line-height: 150%;
            letter-spacing: 0.14px;
          }

          &LoadingWrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;
            color: var(--color-primary-1);
            font-size: var(--font-size-lg);
            font-weight: var(--font-weight-medium);
            line-height: 150%;
            letter-spacing: 0.14px;
            margin-top: 2px;
          }

          &Loading {
            svg {
              width: 14px;
              height: 14px;
              fill: var(--color-primary-1);
            }
          }

          &Task {
            display: flex;
            justify-content: space-between;
            margin-top: 2px;

            &Tag {
              display: flex;
              gap: 8px;
              align-items: center;
            }

            .bbTagChip {
              padding: 1px 6px;
              color: var(--color-on-primary-dark);
              font-size: var(--font-size-sm);
              background-color: var(--color-primary-3);
            }

            .bbMessageTaskScore {
              color: var(--color-on-surface-subtle-1);
              font-size: var(--font-size-lg);
              line-height: 150%;
              letter-spacing: 0.14px;

              span {
                font-weight: var(--font-weight-bold);
              }
            }
          }

          .bbMessageNoTasks {
            color: var(--color-on-surface-subtle-1);
            font-size: var(--font-size-lg);
            font-weight: var(--font-weight-medium);
            line-height: 150%;
            letter-spacing: 0.14px;
          }
        }
      }
    }

    .bbMessageInput {
      font-size: var(--font-size-md);
      line-height: 150%;
      letter-spacing: 0.13px;
      width: 100%;
      height: 124px;
      padding: 12px;
      border-radius: var(--border-radius-md);
      background: var(--color-surface-2);
    }

    .bbMessageInputWrapper {
      border: 1px solid var(--color-surface-5);
    }

    .bbMessageInputWrapperError {
      border: 2px solid var(--color-danger-1);

      &:hover {
        border: 2px solid var(--color-danger-1);
      }
    }

    .bbMessageInfoText {
      font-size: var(--font-size-md);
      color: var(--color-on-surface-subtle-1);
      font-weight: var(--font-weight-medium);
      line-height: 150%;
      letter-spacing: 0.13px;
      margin-top: 4px;
    }

    div[class*="bbTextareaFooter--"] {
      padding: 5px 12px 12px 12px;
      gap: 10px;

      span[class*="bbChip--"] {
        background: none;
      }

      div[class*="bbEmojiPicker--"] {
        border: none;
      }
    }

    .bbSendMessageBtn {
      padding: 8px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-surface-2);
      }
    }
  }
}

.bbAIWorkflowStep6Footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  margin-top: 40px;
  justify-content: space-between;
}

.bbAIWorkflowStep6OkBtn {
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-on-primary);
  }
}

.bbAIWorkflowStep6BackBtn {
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-primary-1);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;